import verticalSidebarItemsBackoffice from '~/models/vertical-sidebar-items-backoffice'
import verticalSidebarItemsGuide from '~/models/vertical-sidebar-items-guide'
import { ElNotification } from 'element-plus'

export default defineNuxtRouteMiddleware((to, from) => {
  const hasToken = $cookies.get('apollo-token')
  if (!hasToken) {
    localStorage.setItem('temporaryRedirection', to.fullPath)
    return navigateTo('/login')
  }
  const roles = getAuthRoles()
  let verticalSidebarItems = verticalSidebarItemsBackoffice
  if (roles?.value?.find((i: { name: string }) => i.name === 'Guide')) {
    verticalSidebarItems = verticalSidebarItemsGuide
  }
  const groups = to.fullPath === '/' ? ['/', '/'] : to.fullPath.match(/\/[^/]+/g)
  if (groups?.length) {
    if (groups[groups.length - 1].includes('?')) {
      groups[groups.length - 1] = groups[groups.length - 1].substring(0, groups[groups.length - 1].indexOf('?'));
    }
    const groupArray = verticalSidebarItems.filter(i => i.group === groups[0])
    if (groupArray?.length && groupArray[0] && hasRoles(roles.value, groupArray[0])) {
      const item = groupArray.map(group => {
        if (group.children && group.children.find(i => ('/' + i.to) === groups[1])) {
          return group
        } else {
          return null
        }
      }).find(i => i !== null)
      if (!item || !hasRoles(roles.value, item)) {
        redirectToLogin(notAuthorizedMessage())
      }
    } else {
      redirectToLogin(notAuthorizedMessage())
    }
  } else {
    redirectToLogin(notAuthorizedMessage())
  }
})

function redirectToLogin(message: any) {
  logout()
  $cookies.remove('apollo-token');
  if (message) {
    ElNotification({
      showClose: true,
      message,
      type: 'error',
      customClass: 'error-notification',
      position: 'bottom-right',
      zIndex: 3000
    })
  }
}

function notAuthorizedMessage() {
  switch (navigator.language) {
    case 'es-ES':
      return 'No estás autorizado a entrar'
    case 'en-EN':
      return 'You are not authorized to enter'
    case 'fr-FR':
      return 'Vous n\'estez pas autorisé d\'entrer'
    case 'de-DE':
      return 'Du bist nicht berechtigt, aufzurufen'
    case 'sv-SE':
      return 'Du är inte godkänt att in'
    case 'ar-AE':
      return 'ليس لديك الصلاحية للدخول'
    default:
      return 'You are not authorized to enter'
  }
}

function hasRoles(roles: any[], item: { roles: any }) {
  if (item?.roles?.length) {
    let result = false
    item.roles.forEach((role: any) => {
      if (roles?.find((i: { name: any }) => i.name === role)) {
        result = true
      }
    })
    return result
  }
  return false
}